import { Options } from "vue-class-component";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import BaseTableMixins from "@/mixins/BaseTable";
import SelectLevel from "@/modules/select-level/select-level.vue";
import DraggableListview from "@/modules/draggable-listview/draggable-listview.vue";
import ProjectTypeTableItem from "@/entity/project/ProjectTypeTableItem";
import ProjectModelAPI from "@/api/project/project-model";
import API from "@/api/project/project-type";

@Options({
    name: "app-project-project-type",
    components: {
        "app-select-level": SelectLevel,
        "app-draggable-listview": DraggableListview,
    },
})
export default class ProjectType extends BaseTableMixins<ProjectTypeTableItem> implements Partial<BaseTableInterface<ProjectTypeTableItem>> {
    private editOption = "crafts";
    public setEditOption(val: any) {
        if (!this.$tools.isEmpty(val)) {
            this.editOption = val.toString() === "-1" ? "type" : "crafts";
        }
    }

    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new ProjectTypeTableItem();
        this.editModalItem = new ProjectTypeTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            projectTypeParentId: {
                type: "router",
                name: "project_type_id",
                title: "设备类型",
                parent: -1,
                lockPid: -1,
                value: null,
                openFn: this.projectTypeParentIdLevelModalOpen,
            },
            projectModelId: {
                type: "eq",
                name: "project_model_id",
                title: "绑定模型",
                parent: -1,
                value: null,
                openFn: this.projectModelIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //模型API
    get getProjectModel(): any {
        return ProjectModelAPI;
    }

    // //列表回调
    // public listCallback(items: Array<ProjectTypeTableItem>): void {
    //     return;
    // }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editOption = "type";
        this.editTableItem = new ProjectTypeTableItem();
        this.editModalItem = new ProjectTypeTableItem();
        this.editModalItem.projectTypeParentId = -1;
        this.editModalItem.projectTypeStatus = 1;
        this.editModalTitle = "添加设备";
        this.editModalSubmitButtonName = "添加";
    }
    //添加提交检测
    public addSubmitChecking(): string {
        const optionName: string = this.editOption === "type" ? "类型" : "工艺";
        if (this.$tools.isEmpty(this.editModalItem.projectTypeName)) return `设备${optionName}名称不能为空！`;
        if (this.$tools.isEmpty(this.editModalItem.projectTypeStatus)) return `设备${optionName}状态不能为空！`;
        if (this.$tools.isEmpty(this.editModalItem.projectTypeParentId)) return `设备类型不能为空！`;
        return null;
    }
    // //添加回调
    // public addModalCallback(res: any): void {
    //     return;
    // }

    //修改弹窗配置
    public modModalSetting(): void {
        this.editOption = this.editModalItem.projectTypeParentId === -1 ? "type" : "crafts";
        this.editModalTitle = "修改设备";
        this.editModalSubmitButtonName = "修改";
    }
    //修改提交检测
    public modSubmitChecking(): string {
        const optionName: string = this.editOption === "type" ? "类型" : "工艺";
        if (this.$tools.isEmpty(this.editModalItem.projectTypeId)) return `设备${optionName}ID不能为空！`;
        return this.addSubmitChecking();
    }
    // //修改回调
    // public modModalCallback(res: any): void {
    //     return;
    // }

    //删除提示框配置
    public delAlertConfig(): any {
        this.editOption = this.editModalItem.projectTypeParentId === -1 ? "type" : "crafts";
        const optionName: string = this.editOption === "type" ? "类型" : "工艺";
        return {
            type: "okAndNo",
            title: `删除设备${optionName}`,
            message: `设备${optionName}: ${this.editModalItem.projectTypeName}<br>请确认删除？`,
        };
    }
    // //删除提示框配置
    // public delAlertSetting(): void {
    //     return;
    // }
    //删除提示框提交检测
    public delSubmitChecking(): string {
        this.editOption = this.editModalItem.projectTypeParentId === -1 ? "type" : "crafts";
        const optionName: string = this.editOption === "type" ? "类型" : "工艺";
        if (this.$tools.isEmpty(this.editModalItem.projectTypeId)) return `设备${optionName}ID不能为空！`;
        return null;
    }
    // //删除回调
    // public delAlertCallback(res: any): void {
    //     return;
    // }

    //状态提示框配置
    public statusAlertConfig(): any {
        this.editOption = this.editModalItem.projectTypeParentId === -1 ? "type" : "crafts";
        const optionName: string = this.editOption === "type" ? "类型" : "工艺";
        return {
            title: `设备${optionName}状态`,
            message: `设备${optionName}: ${this.editModalItem.projectTypeName}<br>请确认修改状态？`,
            submitButtonName: this.editModalItem.projectTypeStatus === 1 ? "启用" : "禁用",
        };
    }
    //状态切换请求配置
    public toggleStatusSetting(): void {
        this.editModalItem.projectTypeStatus = this.editModalItem.projectTypeStatus === 1 ? 2 : 1;
    }
    //状态切换提交检测
    public toggleStatusSubmitChecking(): string {
        this.editOption = this.editModalItem.projectTypeParentId === -1 ? "type" : "crafts";
        const optionName: string = this.editOption === "type" ? "类型" : "工艺";
        if (this.$tools.isEmpty(this.editModalItem.projectTypeId)) return `设备${optionName}ID不能为空！`;
        return null;
    }
    // //状态切换回调
    // public toggleStatusCallback(): void {
    //     return;
    // }

    //排序弹窗配置
    public sortModalSetting(): void {
        this.editOption = this.editModalItem.projectTypeParentId === -1 ? "type" : "crafts";
        const optionName: string = this.editOption === "type" ? "类型" : "工艺";
        this.editSortBaseTitle = `设备${optionName}排序`;
    }

    //projectTypeParentId搜索字段
    private projectTypeParentIdLevelSearchField: any = [
        { field: "project-type_name", name: "名称" },
        { field: "project-type_remark", name: "说明" },
    ];
    //projectTypeParentId层级选择器打开
    public projectTypeParentIdLevelModalOpen() {
        (this.$refs.projectTypeParentIdLevel as any).openModal();
    }
    //projectTypeParentId层级选择器提交
    public async projectTypeParentIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().projectTypeParentId : item;
        this.tableRequestItem.lockItem.projectTypeParentId.value = curItem.value;
        this.tableRequestItem.lockItem.projectTypeParentId.title = curItem.title;
        this.tableRequestItem.lockItem.projectTypeParentId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //projectModelId搜索字段
    private projectModelIdLevelSearchField: any = [
        { field: "model_code", name: "代码" },
        { field: "model_name", name: "名称" },
        { field: "model_remark", name: "说明" },
    ];
    //projectModelId层级选择器打开
    public projectModelIdLevelModalOpen() {
        (this.$refs.projectModelIdLevel as any).openModal();
    }
    //projectModelId层级选择器提交
    public async projectModelIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().projectModelId : item;
        this.tableRequestItem.lockItem.projectModelId.value = curItem.value;
        this.tableRequestItem.lockItem.projectModelId.title = curItem.title;
        this.tableRequestItem.lockItem.projectModelId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get projectTypeParentIdSelectApi() {
        return {
            api: (query: any) => API.idSelect(query),
            parent: "project_type_parent_id",
            status: "project_type_status",
            name: "project_type_name",
            id: "project_type_id",
            cid: this.editModalItem.projectTypeParentId,
            mid: this.editModalItem.projectTypeId,
            lockPid: this.tableRequestItem.lockItem.projectTypeParentId.lockPid,
            extend: true,
        };
    }

    get projectModelIdSelectApi() {
        return {
            api: (query: any) => ProjectModelAPI.idSelect(query),
            status: "model_status",
            name: "model_name",
            id: "model_id",
            cid: this.editModalItem.projectModelId,
            extend: true,
        };
    }
}
